import { Pipe, PipeTransform } from '@angular/core';
import { SCHOOL } from 'src/app/core/dummy/config';

@Pipe({
  name: 'extractFilenameFromString'
})
export class ExtractFilenameFromStringPipe implements PipeTransform {
  transform(fileName: string): unknown {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }
}

@Pipe({
  name: 'replaceStorageId'
})
export class replaceStorageIdPipe implements PipeTransform {
  transform(url: any): any {
    if(url && SCHOOL.key === 'ngs'){
      const str = 'ngsclouddev.appspot.com';
      const replaceUrl = url.replace(/\/b\/.*\/o\//, `/b/${str}/o/`);
      return replaceUrl
    }else{
      return url;
    }

  }
}

